import { Controller } from '@hotwired/stimulus'
import * as d3 from "d3";
import forceBoundary from "d3-force-boundary";
import Api from "@utils/api";
import 'long-press-event';

const ANSWERS = {
  0: 'Not Selected',
  1: 'Maybe',
  2: 'Probably',
  3: 'Definitely',
}

export default class extends Controller {
  static values = { 
    connections: Array,
    questionnaireId: Number,
    questionId: Number,
    mode: String,
    active: Boolean,
    pdf: Boolean,
  }
  static targets = [ "svg", "nextButton", "nobodyButton", "skipButton" ]

  config = {}  

  updateConfig() {
    this.width = this.svg.property('clientWidth'); // get width in pixels
    this.height = this.svg.property('clientHeight'); // get height in pixels
    this.centerX = this.width * 0.5;
    this.centerY = this.height * 0.5;

    this.config = {
      collide_strength: 0.7, // The strength circles are pushed away from each other
      collide_distance: 20, // The distance between circles
      center_gravity_strength: 0.1, // the strength of the force that pulls circles to the center
      boundary_strength: 0.1, // the strength of the force that keeps circles away from the boundary
      boundary_x: 100, // the distance from the left and right edges that the circles are kept away from
      boundary_y: 100, // the distance from the top and bottom edges that the circles are kept away from
      alpha_reset: 0.5, // the starting alpha value for the simulation
      alpha_min: 0.01, // the minimum alpha value for the simulation
      circle_start_radius: 59, // the starting radius of the circles
      circle_increment: 20, // the amount the radius increases with each increase in value, i.e. maybe = 1 increment, probably = 2 increments, definitely = 3 increments
      velocity_decay: 0.7, // the rate at which the circles slow down within the simulation
    }

    // check window size
    if (this.width < 600) {
      this.config.circle_start_radius = 50;
      this.config.circle_increment = 12;
      this.config.boundary_x = 50;
      this.config.boundary_y = 50;
      this.config.collide_strength = 0.7,
      this.config.collide_distance = 10;
      this.config.boundary_strength = 0.1;
    }

    let params = new URLSearchParams(window.location.search);

    this.config.collide_strength = parseFloat(params.get('collide_strength')) || this.config.collide_strength;
    this.config.collide_distance = parseInt(params.get('collide_distance')) || this.config.collide_distance;
    this.config.center_gravity_strength = parseFloat(params.get('center_gravity_strength')) || this.config.center_gravity_strength;
    this.config.boundary_strength = parseFloat(params.get('boundary_strength')) || this.config.boundary_strength;
    this.config.boundary_x = parseInt(params.get('boundary_x')) || this.config.boundary_x;
    this.config.boundary_y = parseInt(params.get('boundary_y')) || this.config.boundary_y;
    this.config.alpha_reset = parseFloat(params.get('alpha_reset')) || this.config.alpha_reset;
    this.config.alpha_min = parseFloat(params.get('alpha_min')) || this.config.alpha_min;
    this.config.circle_start_radius = parseInt(params.get('circle_start_radius')) || this.config.circle_start_radius;
    this.config.circle_increment = parseInt(params.get('circle_increment')) || this.config.circle_increment;
    this.config.velocity_decay = parseFloat(params.get('velocity_decay')) || this.config.velocity_decay;

    console.log('config', this.config);
  }

  connect () {
    this.svg = d3.select(this.svgTarget);
    this.simulation = d3.forceSimulation()
    this.updateConfig();

    this.updateSimulation();
    this.setupNodes();
    d3.select(window).on('resize',this.resize.bind(this));
    this.updateButtonState();
    this.updateTooltipDimensions();

    this.calculateSize();
    // Run simulation to calculate intial starting positions, see https://github.com/d3/d3-force/blob/master/README.md#simulation_tick
    for (var i = 0, n = Math.ceil(Math.log(this.simulation.alphaMin()) / Math.log(1 - this.simulation.alphaDecay())); i < n; ++i) {
      this.simulation.tick();
    }
    this.calculateSize();
    this.simulation.restart();

    this.simulation.on('end', this.calculateSize.bind(this));

    // setInterval(this.calculateSize.bind(this), 1000);

    // Removes mouse down class when mouse up, even if mouse up is on a different element
    // window.addEventListener('mouseup', (event) => {
    //   console.log('mouseup', event)
    //   document.querySelectorAll(".node.mousedown").forEach(node => {
    //     node.classList.remove('mousedown')
    //   })
    // })
  }

  updateSimulation({ run = false, reset = false } = {}) {
    this.simulation
      // .force('charge', d3.forceManyBody().strength(0.1))
      .force("boundary", forceBoundary(
        this.config.boundary_x, 
        this.config.boundary_y,
        this.width - this.config.boundary_x, 
        this.height - this.config.boundary_y
      )
      .strength(this.config.boundary_strength))
      .force('x', d3.forceX(this.centerX ).strength(this.config.center_gravity_strength))
      .force('y', d3.forceY(this.centerY ).strength(this.config.center_gravity_strength))
      .force('collide', d3.forceCollide(d => d.radius + this.config.collide_distance).strength(this.config.collide_strength))
      // .force('friction', d3.forceCenter(this.centerX, this.centerY).strength(0)) 
      // .force('friction', d3.forceY(this.centerY).strength(0))      
      .alphaMin(this.config.alpha_min)
      .velocityDecay(this.config.velocity_decay)

    if (reset) {
      this.simulation.alpha(this.config.alpha_reset);
    }

    if (run) {
      this.simulation.restart();
    } else {
      this.simulation.stop();
    }
  }

  setupNodes() {
    this.nodes = this.connectionsValue;

    if (!this.modeValue == "summary")  {  
      this.nodes = this.nodes.filter(node => node.value > 0);
    }

    if (!this.activeValue) {
      this.nodes = this.nodes.filter(node => node.type != 'add' && node.value > 0);
    }

    if (this.pdfValue && this.nodes.length > 8) {
      this.config.circle_start_radius = 45;
      this.config.circle_increment = 20;
    }

    if (this.pdfValue && this.nodes.length > 15) {
      this.config.circle_start_radius = 40;
      this.config.circle_increment = 10;
    }

    this.nodes = this.nodes.map(node => {
      return {
        // r: 0, // for tweening
        r: this.config.circle_start_radius + (node.value > 1 ? (node.value -1) * this.config.circle_increment : 0), // original radius
        radius: this.config.circle_start_radius + (node.value > 1 ? (node.value -1) * this.config.circle_increment : 0), // original radius
        id: node.id,
        name: node.name,
        value: node.value,
        type: node.type,
        icon: node.icon,
        url: node.url,
      };
    });

    for (let i = 0; i < this.nodes.length; i++) {
      const position = this.findClosestSpot(this.nodes[i].radius)
      this.nodes[i].x = position.x;
      this.nodes[i].y = position.y;
    }

    this.simulation.nodes(this.nodes).on('tick', this.ticked.bind(this));

    this.node = this.svg.selectAll('.node')
      .data(this.nodes)
      .enter()
        .append('g')
        .attr('class', d => `node ${d.type}`)
        .classed('selected', d => d.value > 0)
        .attr('data-long-press-delay', 600)
    
        // add the circle
    this.node.append('circle')
      .attr('id', d => d.id)
      .attr('r', d => d.radius)
    
    // Add the emoji
    this.node
      .append('image')
      .classed('node-icon', true)
      .attr('xlink:href', d => d.icon)
      .attr('x', -18) // 36/2
      .attr('y', d => d.type == 'add' ? -18 : -30) // 36/2
      .attr('height', 36)
      .attr('width', 36)
    
    // Add the name
    this.node.append('text')
      .attr('text-anchor', 'middle')
      .attr('class', 'name')
      .text(d => (d.name));
    
    // Add the tooltip
    let tooltip = this.node
      .append('g')
        .attr('class', 'tooltip')
        .attr('transform', 'translate(-100, 0)')
        .attr('y', d => -d.radius - 40)
    
    // the tooltip background
    tooltip.append('rect')
      .attr('rx', '14')
      .attr('ry', '14')
      .attr('width', '200')
      .attr('height', '28')
      .attr('fill', 'black')

    // the tooltip text
    tooltip.append('text')
      .attr('text-anchor', 'middle')
      .text(d => (ANSWERS[d.value]));
    
    let simulation = this.simulation        
    let config = this.config

    this.addLongPressListener(this.node)

    // Grow the Circle when clicking on a node
    this.node.on('click', (event, d) => {
      console.log('click', event, d)
      event.stopPropagation();
    
      let currentTarget = event.currentTarget;
      let currentNode = d;
      
      // Handle the 'Add Person' button
      if (currentNode.type == 'add') {
        Turbo.visit(currentNode.url);
        return;
      }
    
      if (this.modeValue == "summary" || this.modeValue == "start" || !this.activeValue) {
        return;
      }

      currentNode.value = currentNode.value ? currentNode.value + 1 : 1;
      if (currentNode.value > 3) {
        currentNode.value = 0 
      }

      d3.select(currentTarget)
        .classed('selected', currentNode.value > 0)
        .raise()

      this.storeAnswer(currentNode); // Submit to the API Server
      this.updateButtonState();

      // Show the tooltip and set the text to the current value selected
      d3.select(currentTarget).select('.tooltip text')
        .text(d => (ANSWERS[d.value]));
      this.updateTooltipDimensions();
      d3.select(currentTarget).select('.tooltip').classed('show', true);

      // Hide the tooltip after 1 second
      clearTimeout(currentTarget.tooltipTimeout);
      currentTarget.tooltipTimeout = setTimeout(() => {
        d3.select(currentTarget).select('.tooltip').classed('show', false);
      }, 800);

      d3.transition().duration(500).ease(d3.easePolyInOut.exponent(2))
      // d3.transition().duration(500).ease(d3.easeLinear)
        .tween('moveIn', () => {          
          let newR = config.circle_start_radius;
          if (currentNode.value > 1) {
            newR = newR + (currentNode.value-1)*config.circle_increment;
          }
          
          let ir = d3.interpolateNumber(currentNode.r, newR);
          return function (t) {
            currentNode.r = ir(t);
            currentNode.radius = ir(t);
            simulation.force('collide', d3.forceCollide(d => d.radius + config.collide_distance).strength(config.collide_strength));
          };
        })

        // // This is a bit cumbersome but avoids a jerky transition when circle are expanding
        this.simulation
          .force('x', d3.forceX(this.centerX).strength(0.1*config.center_gravity_strength))
          .force('y', d3.forceY(this.centerY).strength(0.1*config.center_gravity_strength))
          .alpha(config.alpha_reset)
          .restart();

          setTimeout(() => {
          this.simulation
            .force('x', d3.forceX(this.centerX).strength(0.3*config.center_gravity_strength))
            .force('y', d3.forceY(this.centerY).strength(0.3*config.center_gravity_strength))
            .restart();
        }, 1000)
  
        setTimeout(() => {
          this.simulation
            .force('x', d3.forceX(this.centerX).strength(config.center_gravity_strength))
            .force('y', d3.forceY(this.centerY).strength(config.center_gravity_strength))
            .restart();
        }, 2000)
    })
  }

  findClosestSpot(r) {
    let closestX = this.centerX;
    let closestY = this.centerY;
    let minDistance = Infinity;
  
    for (let x = 0; x <= this.width; x++) {
      for (let y = 0; y <= this.height; y++) {
        let distance = Math.sqrt((x - this.centerX) ** 2 + (y - this.centerY) ** 2);
        if (distance < minDistance && this.isSpotValid(x, y, r)) {
          minDistance = distance;
          closestX = x;
          closestY = y;
        }
      }
    }
  
    return {x :closestX + 10, y: closestY + 10};
  }

  isSpotValid(x, y, r) {
    for (let node of this.nodes) {
      if (node.x && node.y) {
        let dx = node.x - x;
        let dy = node.y - y;
        let distance = Math.sqrt(dx ** 2 + dy ** 2);
        if (distance < node.radius + r + 40) {
          return false;
        }
      }
    }
    return true;
  }

  ticked() {
    // console.log('ticked' , this.node)
    
    // Update the positions, the size of the circle and the position of the tooltip
    this.node
      .attr('transform', d => `translate(${d.x},${d.y})`)
      .classed('started', true)
      .select('circle')
        .attr('r', d => d.r)
        
    // Tooltip is updated to move with the radius of the circle        
    this.node
      .select('.tooltip')
      .attr('transform', d => `translate(0,${-d.r - 20})`)
  }

  // checks if we need to expand the SVG to accomodate more circles or growing circles
  calculateSize() {
    let minX = 2000;
    let maxX = -2000;
    let minY = 2000;
    let maxY = -2000;
    this.nodes.forEach(node => {
      minX = Math.min(node.x - node.radius, minX)
      maxX = Math.max(node.x + node.radius, maxX)
      minY = Math.min(node.y - node.radius, minY)
      maxY = Math.max(node.y + node.radius, maxY)
    })

    maxY += 50;
    if (maxY > this.height) {
      this.svg.attr('height', `${maxY}`);
      
      this.width = this.svg.property('clientWidth'); // get width in pixels
      this.height = this.svg.property('clientHeight'); // get height in pixels
      this.centerX = this.width * 0.5;
      this.centerY = this.height * 0.5;

      this.simulation
        .force("boundary", forceBoundary(
          this.config.boundary_x, 
          this.config.boundary_y,
          this.width - this.config.boundary_x, 
          this.height - this.config.boundary_y
        )
        .strength(this.config.boundary_strength))
        .force('x', d3.forceX(this.centerX ).strength(this.config.center_gravity_strength))
        .force('y', d3.forceY(this.centerY ).strength(this.config.center_gravity_strength))  
    }
  }

  resize() {
    if ((this.width != this.svg.property('clientWidth'))) {
      this.updateConfig();
      this.calculateSize();
      this.updateSimulation({run: true, reset: true});
    }
  }

  storeAnswer(currentNode) {
    Api.post(`/questionnaires/${this.questionnaireIdValue}/questions/${this.questionIdValue}/answers/save`, { connection_id: currentNode.id, value: currentNode.value } );
  }

  updateButtonState() {
    if (this.hasNextButtonTarget) {
      const totalValue = this.nodes.map(node => node.value).reduce((a, b) => a + b)
      if (totalValue > 0) {
        this.nobodyButtonTarget.querySelector("button").disabled = true;
        this.skipButtonTarget.classList.add("hidden");
        this.nextButtonTarget.classList.remove("hidden");        
      } else {
        this.nobodyButtonTarget.querySelector("button").disabled = false;
        this.skipButtonTarget.classList.remove("hidden");
        this.nextButtonTarget.classList.add("hidden");        
      }
    }
  }

  // Update Tooltip Dimensions
  // Retrieves the size of the tooltip text and updates the rect dimensions to match
  updateTooltipDimensions() {
    this.svg.selectAll(".tooltip text")
      .each(function(d) { d.bbox = this.getBBox() });

    // Update the rectangles using the sizes we just added to the data
    const xPadding = 12
    const yPadding = 7
    this.svg.selectAll(".tooltip rect")
      // .data(data)
      .join("rect")
        .attr("width", d => d.bbox.width + 2 * xPadding)
        .attr("height", d => d.bbox.height + 2 * yPadding)
        .attr('transform', function(d) {
            return `translate(-${xPadding + d.bbox.width/2}, -${d.bbox.height * 0.8 + yPadding})`
        });
  }

  // Adds a long press listener to the node
  // When the node is long pressed, it will navigate to the url to edit the connection
  // .mousedown class is used to set a shadow feedback on press
  // the long-press event is a custom event added by long-press-event
  // The delay is controlled via the data-long-press-delay on the .node element
  addLongPressListener(node) {
    node.on('mousedown', (event, d) => {
      d3.select(event.currentTarget)
        .classed('mousedown', true)
    })
    node.on('touchstart', (event, d) => {
      console.log('touchstart')
      d3.select(event.currentTarget)
        .classed('mousedown', true)
    })    

    node.on('mouseleave', (event, d) => {
      d3.select(event.currentTarget)
        .classed('mousedown', false)
    })
    node.on('touchleave', (event, d) => {
      d3.select(event.currentTarget)
        .classed('mousedown', false)
    })

    node.on('mouseup', (event, d) => {
      d3.select(event.currentTarget)
        .classed('mousedown', false)
    })
    node.on('touchend', (event, d) => {
      d3.select(event.currentTarget)
        .classed('mousedown', false)
    })

    node.on('long-press', (event, d) => {
      event.stopPropagation();
      Turbo.visit(d.url);
    })
  }

}
